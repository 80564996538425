import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  exerciseFormsDiv: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: 'white',
    gap: '10px',
    width: '100%',
    borderRadius: '5px',
    padding: '20px',
    boxShadow: '0px 0px 3px 1px #00000029'
  },
  formSectionDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%'
  },
  questionDifficulty: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  questionType: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  radioButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    fontSize: '15px'
  },
  subjectInput: {
    border: 'solid 1px blue',
    borderRadius: '3px',
    width: '60%',
    height: '30px'
  },
  numberOfQuestionsInput: {
    border: 'solid 1px blue',
    borderRadius: '3px',
    width: '60%',
    height: '30px',
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0'
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0'
    },
    'input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },
  deleteButtonSquare: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    minWidth: '0'
  },
  deleteButtonSquareDisabled: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    minWidth: '0',
    pointerEvents: 'none'
  },
  deleteButton: {
    fontSize: '28px',
    color: '#af0000'
  },
  deleteButtonDisabled: {
    fontSize: '28px',
    color: '#888'
  }
}));
