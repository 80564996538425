import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    minWidth: '320px',
    width: '40%',
    borderRadius: '8px',
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'justify-between',
    gap: '24px',
    overflow: 'auto',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.main
    },
    '@media (min-width: 0px) and (max-width: 450px)': {
      width: '90%'
    },
    '@media (min-width: 451px) and (max-width: 1190px)': {
      width: '60%'
    }
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '16px',
    width: '100%'
  },
  button: {
    padding: '8px 24px',
    width: '121px',
    height: '40px'
  },
  cancelButton: {
    fontSize: '16px',
    fontHeight: '24px',
    color: theme.palette.primary.main
  },
  importButton: {
    fontSize: '16px',
    fontHeight: '24px',
    color: 'white'
  },
  generationExerciseFormsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: `8px`,
    width: '100%'
  },
  generationExerciseFormsDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    overflowY: 'auto',
    padding: `2px 4px 3px`,
    borderRadius: `8px`,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888'
    }
  },
  bottomButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '13px',
    width: '100%',
    paddingTop: '20px',
    borderTop: '1px solid #1a72f9'
  },

  iconCustom: {
    padding: 0,
    margin: 0,
    width: 12,
    height: 12,
    color: 'white'
  },
  iconCustomWraper: {
    padding: 0,
    margin: 0,
    width: 18,
    height: 18,
    background: '#1B60F3',
    '&:hover': { background: '#083491' },
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: 30,
      width: 30,
      marginTop: 0,
      left: 0
    }
  },
  toggle: {
    cursor: 'pointer'
  },
  field: {
    marginBottom: '10px'
  },
  exerciseFormsDiv: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: 'white',
    gap: '10px',
    width: '100%',
    borderRadius: '5px',
    padding: '20px',
    boxShadow: '0px 0px 3px 1px #00000029'
  },
  formSectionDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%'
  },
  questionDifficulty: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  questionType: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  radioButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    fontSize: '15px'
  },
  subjectInput: {
    border: 'solid 1px blue',
    borderRadius: '3px',
    width: '60%',
    height: '30px'
  },
  numberOfQuestionsInput: {
    border: 'solid 1px blue',
    borderRadius: '3px',
    width: '60%',
    height: '30px',
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0'
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0'
    },
    'input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },
  deleteButtonSquare: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    minWidth: '0'
  },
  deleteButtonSquareDisabled: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    minWidth: '0',
    pointerEvents: 'none'
  },
  deleteButton: {
    fontSize: '28px',
    color: '#af0000'
  },
  deleteButtonDisabled: {
    fontSize: '28px',
    color: '#888'
  },
  defaultLoader: {
    width: '20px',
    padding: '4px',
    aspectRatio: '1',
    borderRadius: '50%',
    background: 'white',
    '--_m':
      'conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box',
    '-webkit-mask': 'var(--_m)',
    mask: 'var(--_m)',
    '-webkit-mask-composite': 'source-out',
    'mask-composite': 'subtract',
    animation: '$defaultLoaderKeyframe 1s infinite linear'
  },
  '@keyframes defaultLoaderKeyframe': {
    to: {
      transform: 'rotate(1turn)'
    }
  }
}));
