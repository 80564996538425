import React from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import { DeleteIcon } from '~/components/atoms/Icons';
import Text from '~/components/atoms/Text';

import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';

function GenerationExerciseForms({
  id,
  subject,
  difficulty,
  type,
  numberOfQuestions,
  deleteCardFunction,
  onChange = () => {}
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.exerciseFormsDiv}>
      <ButtonSquare
        className={
          deleteCardFunction
            ? classes.deleteButtonSquare
            : classes.deleteButtonSquareDisabled
        }
        onClick={deleteCardFunction}
      >
        <DeleteIcon
          className={
            deleteCardFunction
              ? classes.deleteButton
              : classes.deleteButtonDisabled
          }
        />
      </ButtonSquare>
      <div className={classes.formSectionDiv}>
        <Text
          color="primary"
          variant="lg"
          style={{ letterSpacing: 1, fontWeight: 500 }}
        >
          Nível de dificuldade:
        </Text>
        <div className={classes.questionDifficulty}>
          {['Fácil', 'Médio', 'Difícil'].map((difficultyText, index) => (
            <div key={difficultyText} className={classes.radioButton}>
              <input
                type="radio"
                id={difficultyText}
                name={`questionDifficulty${id}`}
                value={difficultyText}
                defaultChecked={index + 1 == difficulty ? true : false}
                onChange={() => onChange('difficulty', index + 1)}
              />
              <label htmlFor={`questionDifficulty${id}`}>
                {difficultyText}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.formSectionDiv}>
        <Text
          color="primary"
          variant="lg"
          style={{ letterSpacing: 1, fontWeight: 500 }}
        >
          Assunto:
        </Text>
        <input
          type="text"
          id={`questionSubject${id}`}
          defaultValue={subject}
          className={classes.subjectInput}
          onChange={(e) => onChange('subject', e.target.value)}
        />
      </div>
      <div className={classes.formSectionDiv}>
        <Text
          color="primary"
          variant="lg"
          style={{ letterSpacing: 1, fontWeight: 500 }}
        >
          Tipo de questão:
        </Text>
        <div className={classes.questionType}>
          {['Múltipla Escolha', 'Verdadeiro ou Falso'].map((questionType) => (
            <div key={questionType} className={classes.radioButton}>
              <input
                type="radio"
                id={questionType}
                name={`questionType${id}`}
                value={questionType}
                defaultChecked={questionType == type ? true : false}
                onChange={() => onChange('type', questionType)}
              />
              <label htmlFor={`questionType${id}`}>{questionType}</label>
            </div>
          ))}
        </div>
      </div>
      <div
        className={classes.formSectionDiv}
        style={{ flexDirection: 'row', marginTop: '10px' }}
      >
        <Text
          color="primary"
          variant="lg"
          style={{ letterSpacing: 1, fontWeight: 500 }}
        >
          Quantidade:
        </Text>
        <input
          type="number"
          id={`numberOfQuestions${id}`}
          defaultValue={numberOfQuestions}
          className={classes.numberOfQuestionsInput}
          onChange={(e) => onChange('numberOfQuestions', e.target.value)}
          style={{ width: '39px' }}
        />
      </div>
    </div>
  );
}

export default GenerationExerciseForms;
