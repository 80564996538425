import React, { useState, useRef } from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from './styles';
import { Backdrop, Fade, Modal, Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import ImportExportIcon from '@material-ui/icons/ImportExport';

function IAGenerationQuestionModal({
  open,
  handleClose,
  generateQuestionFunction
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [loadingCreation, setloadingCreation] = useState(false);
  const [error, setError] = useState('');
  const [questionData, setQuestionData] = useState({
    id: 0,
    difficulty: '',
    subject: '',
    type: ''
  });
  const questionArrayDivRef = useRef(null);

  const secondaryText = (text) => (
    <Text color="gray" variant="lg" style={{ fontWeight: 400 }}>
      {text}
    </Text>
  );

  const clearAndClose = () => {
    handleClose();
    setQuestionData({
      id: 0,
      difficulty: '',
      subject: '',
      type: ''
    });
  };

  const handleGenerateQuestion = async () => {
    if (
      questionData.subject == '' ||
      questionData.difficulty == '' ||
      questionData.type == ''
    ) {
      setError('Preencha as informações da questão para realizar a geração...');
      setTimeout(() => {
        setError('');
      }, 3000);
      return;
    }
    setloadingCreation(true);
    await generateQuestionFunction(questionData);
    setloadingCreation(false);
  };

  function Buttons() {
    return (
      <div className={classes.buttonsContainer}>
        <ButtonSquare onClick={clearAndClose} className={classes.button}>
          <Typography className={classes.cancelButton}>
            {i18n.t('Cancel')}
          </Typography>
        </ButtonSquare>
        <ButtonSquare
          backgroundColor={theme.palette.primary.main}
          className={classes.button}
          onClick={() => handleGenerateQuestion()}
        >
          {loadingCreation ? (
            <div className={classes.defaultLoader}></div>
          ) : (
            <Typography className={classes.importButton}>
              {i18n.t('Confirm')}
            </Typography>
          )}
        </ButtonSquare>
      </div>
    );
  }

  function Title() {
    return (
      <>
        <div className={classes.titleContainer}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <ImportExportIcon style={{ fontSize: '24px' }} />
            <Text
              color="primary"
              variant="lgTitle"
              style={{ letterSpacing: 1, fontWeight: 500 }}
            >
              Geração com IA
            </Text>
          </div>
          <ButtonSquare onClick={clearAndClose}>
            <CloseIcon style={{ fontSize: '32px' }} />
          </ButtonSquare>
        </div>
        {secondaryText(
          'Preencha as informações abaixo e confirme para que a geração da questão seja iniciada.'
        )}
      </>
    );
  }

  return (
    <Modal
      id="modal-modal"
      disableEnforceFocus
      aria-labelledby="lecture-form"
      aria-describedby="modal-for-lecture-form"
      className={classes.modal}
      open={open}
      // onClose={}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Title />
          <div className={classes.generationExerciseFormsContainer}>
            <div
              className={classes.generationExerciseFormsDiv}
              style={{ width: '100%' }}
              ref={questionArrayDivRef}
            >
              <div className={classes.exerciseFormsDiv}>
                <div className={classes.formSectionDiv}>
                  <Text
                    color="primary"
                    variant="lg"
                    style={{ letterSpacing: 1, fontWeight: 500 }}
                  >
                    Nível de dificuldade:
                  </Text>
                  <div className={classes.questionDifficulty}>
                    {['Fácil', 'Médio', 'Difícil'].map(
                      (difficultyText, index) => (
                        <div
                          key={difficultyText}
                          className={classes.radioButton}
                        >
                          <input
                            type="radio"
                            id={difficultyText}
                            name={`questionDifficulty`}
                            value={difficultyText}
                            onChange={(e) =>
                              setQuestionData((prevState) => ({
                                ...prevState,
                                difficulty: e.target.value
                              }))
                            }
                          />
                          <label htmlFor={`questionDifficulty`}>
                            {difficultyText}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className={classes.formSectionDiv}>
                  <Text
                    color="primary"
                    variant="lg"
                    style={{ letterSpacing: 1, fontWeight: 500 }}
                  >
                    Assunto:
                  </Text>
                  <input
                    type="text"
                    id={`questionSubject`}
                    defaultValue=""
                    className={classes.subjectInput}
                    onChange={(e) =>
                      setQuestionData((prevState) => ({
                        ...prevState,
                        subject: e.target.value
                      }))
                    }
                  />
                </div>
                <div className={classes.formSectionDiv}>
                  <Text
                    color="primary"
                    variant="lg"
                    style={{ letterSpacing: 1, fontWeight: 500 }}
                  >
                    Tipo de questão:
                  </Text>
                  <div className={classes.questionType}>
                    {['Múltipla Escolha', 'Verdadeiro ou Falso'].map(
                      (questionType) => (
                        <div key={questionType} className={classes.radioButton}>
                          <input
                            type="radio"
                            id={questionType}
                            name={`questionType`}
                            value={questionType}
                            onChange={(e) =>
                              setQuestionData((prevState) => ({
                                ...prevState,
                                type: e.target.value
                              }))
                            }
                          />
                          <label htmlFor={`questionType`}>{questionType}</label>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.bottomButtonsContainer}>
            {error !== '' && (
              <Text
                color="error"
                variant="mediumText"
                style={{ letterSpacing: 1 }}
              >
                {error}
              </Text>
            )}
            <Buttons />
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
}

export default IAGenerationQuestionModal;
