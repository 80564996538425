import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Text from '~/components/atoms/Text';
import api from '~/services/api';
import { TextPrimary } from '~/utils/forms';

import AddQuestionButton from './AddQuestionButton';
import AlternativeFields from './AlternativeFields';
import Alternatives from './Alternatives';
import Statements from './Statements';
import { useStyles } from './styles';
import { Button, Collapse, Grid, useTheme } from '@material-ui/core';
import IAGenerationQuestionModal from '../IAGenerationQuestionModal';

export default function QuestionFields({
  addQuestion,
  currentQuestion,
  resetQuestionFields,
  editingQuestion
}) {
  const theme = useTheme();
  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const { setValue } = useFormContext();
  const [alternatives, setAlternatives] = useState([]);
  const [errorStatement, setErrorStatement] = useState(false);
  const [errorAlternative, setErrorAlternative] = useState(false);
  const [errorRightAlternative, setErrorRightAlternative] = useState(false);
  const [showQuestionFields, setShowQuestionFields] = useState(false);
  const [currentAlternative, setCurrentAlternative] = useState({
    id: null,
    correct: false,
    text: '',
    letter: 'A'
  });

  const [statement, setStatement] = useState('');
  const [explanation, setExplanation] = useState('');
  const [weight, setWeight] = useState(1.0);

  const [startStatement, setStartStatement] = useState('');
  const [startExplanation, setStartExplanation] = useState('');

  const [showGenerationQuestioModal, setShowGenerationQuestioModal] = useState(
    false
  );

  const deleteAlternative = (alternative) => {
    let letter = 'A';
    setAlternatives((prev) => {
      if (alternative.id) alternative._destroy = true;
      alternative.letter = '';
      alternative.correct = false;

      const destroyedAlternatives = prev.filter((elem) => elem._destroy);
      const validAlternatives = prev.filter(
        (elem) => !elem._destroy && elem.letter !== ''
      );

      for (let item of validAlternatives) {
        item.letter = letter;
        letter = getNextChar(letter);
      }
      setCurrentAlternative({ correct: false, text: '', letter: letter });

      return [...validAlternatives, ...destroyedAlternatives];
    });

    setEditing(false);
  };

  const setAlternativeEdition = (alternative) => {
    setCurrentAlternative(alternative);
    setEditing(true);
  };

  const resetFields = () => {
    const validAlternatives = alternatives.filter((elem) => !elem._destroy);
    const letter = validAlternatives.at(-1)?.letter ?? 'A';
    setCurrentAlternative({
      correct: false,
      text: '',
      letter: getNextChar(letter)
    });
    setEditing(false);
  };

  const resetAlternativeFields = () => {
    setAlternatives([]);
    setCurrentAlternative({ correct: false, text: '', letter: 'A' });
    setWeight(1.0);
    setStatement('');
    setExplanation('');
  };

  const resetErrorFlags = () => {
    setErrorStatement(false);
    setErrorAlternative(false);
    setErrorRightAlternative(false);
  };

  const handleAddQuestion = () => {
    var question = {
      id: currentQuestion.id,
      weight,
      alternatives,
      statement,
      explanation,
      position: currentQuestion.position
    };

    setValue(`lectureable.exercise.questions[${currentQuestion.position - 1}]`, question);

    if (addQuestion(question)) {
      setShowQuestionFields(false);
      resetAlternativeFields();
      resetErrorFlags();
    } else {
      resetErrorFlags();
      if (!question.statement) {
        setErrorStatement(true);
      } else if (question.alternatives.length <= 1) {
        setErrorAlternative(true);
      } else if (
        question.alternatives.filter((elem) => elem.correct).length !== 1
      ) {
        setErrorRightAlternative(true);
      }
    }
  };

  const handleCancel = () => {
    setShowQuestionFields(false);
    resetQuestionFields();
  };

  const handleGenerateQuestion = async (questionData) => {
    await api
      .get(`api/courses/generate_question_with_ia`, {
        params: {
          questionsArray: JSON.stringify([
            {
              id: 0,
              difficulty: questionData.difficulty,
              subject: questionData.subject,
              type: questionData.type,
              numberOfQuestions: '1'
            }
          ])
        }
      })
      .then((response) => {
        response.data.answer.forEach((questions) => {
          let alternatives = [];

          var lines = questions.split('\n');
          lines.forEach((line, index) => {
            if (line.toLowerCase().includes('resposta correta')) {
              alternatives.forEach((alternative) => {
                if (
                  line.split(':')[1][1].toLowerCase() ==
                  alternative.letter.toLowerCase()
                ) {
                  alternative.correct = true;
                }
                addAlternative(alternative);
              });
            } else if (
              index + 1 < lines.length &&
              lines[index + 1].toLowerCase().includes('a)')
            ) {
              setStatement(line);
              setStartStatement(line);
            } else if (
              line.toLowerCase().includes('a)') ||
              line.toLowerCase().includes('b)') ||
              line.toLowerCase().includes('c)') ||
              line.toLowerCase().includes('d)') ||
              line.toLowerCase().includes('e)')
            ) {
              alternatives.push({
                id: null,
                correct: false,
                text: `<p>${line.substring(2)}</p>`,
                letter: `${String.fromCharCode(65 + alternatives.length)}`
              });
            }
          });
        });
        setShowGenerationQuestioModal(false);
      })
      .catch((error) => {
        console.log(error);
        setShowGenerationQuestioModal(false);
      });
  };

  const getNextChar = (char) => {
    return String.fromCharCode(char.charCodeAt(0) + 1);
  };

  const addAlternative = (alternative) => {
    var existingAlternative = alternatives.find(
      (elem) => elem.letter === alternative.letter && !elem._destroy
    );
    var index = alternatives.findIndex(
      (elem) => elem.letter === alternative.letter && !elem._destroy
    );
    if (existingAlternative) {
      if (
        alternative.correct &&
        alternatives.filter(
          (elem) =>
            !elem._destroy &&
            elem.correct &&
            elem.letter !== existingAlternative.letter
        ).length > 0
      ) {
        window.alert('A questão já possui uma alternativa correta.');
        return false;
      }

      setErrorAlternative(false);
      setErrorRightAlternative(false);

      alternatives[index] = alternative;
      var newAlternatives = alternatives;

      setAlternatives(newAlternatives);
      const validAlternatives = alternatives.filter((elem) => !elem._destroy);
      setCurrentAlternative({
        correct: false,
        text: '',
        letter: getNextChar(validAlternatives.at(-1).letter)
      });
      setEditing(false);

      return true;
    } else {
      if (!alternative.text) {
        window.alert('Você precisa preencher o enunciado da alternativa.');
        return false;
      }

      if (
        alternative.correct &&
        alternatives.filter((elem) => elem.correct && !elem._destroy).length > 0
      ) {
        window.alert('A questão já possui uma alternativa correta.');
        return false;
      }
      setAlternatives((prev) => [...prev, alternative]);
      setCurrentAlternative({
        correct: false,
        text: '',
        letter: getNextChar(alternative.letter)
      });
      setEditing(false);
      return true;
    }
  };

  useEffect(() => {
    if (editingQuestion) {
      setStartStatement(currentQuestion.statement);
      setStartExplanation(currentQuestion.explanation);
      setAlternatives(currentQuestion.alternatives);
      const validAlternatives = currentQuestion.alternatives.filter(
        (elem) => !elem._destroy
      );
      setCurrentAlternative({
        id: null,
        correct: false,
        text: '',
        letter: getNextChar(validAlternatives.at(-1)?.letter ?? 'A')
      });
    }
  }, [editingQuestion]);

  return (
    <div>
      <Collapse in={!showQuestionFields && !editingQuestion}>
        <AddQuestionButton setState={setShowQuestionFields} />
      </Collapse>

      <IAGenerationQuestionModal
        generateQuestionFunction={(question) =>
          handleGenerateQuestion(question)
        }
        open={showGenerationQuestioModal}
        handleClose={() => setShowGenerationQuestioModal(false)}
      />

      <Collapse in={showQuestionFields || editingQuestion}>
        <div className={classes.questionField}>
          <Text color={'primary'} variant={'subtitle'}>
            {' '}
            Questão{' '}
            <span style={{ fontWeight: 'bold' }}>
              {currentQuestion.position}
            </span>{' '}
          </Text>
        </div>

        <Statements
          statementState={statement}
          setStatementState={setStatement}
          explanation={explanation}
          setExplanation={setExplanation}
          startStatement={startStatement}
          startExplanation={startExplanation}
          errorStatement={errorStatement}
        />

        <div className={classes.field}>
          {alternatives.length > 0 ? (
            <Alternatives
              alternatives={alternatives}
              deleteFunc={deleteAlternative}
              editFunc={setAlternativeEdition}
            />
          ) : null}
        </div>

        <AlternativeFields
          addAlternative={addAlternative}
          currentAlternative={currentAlternative}
          resetFields={resetFields}
          editing={editing}
          errorAlternative={errorAlternative}
          errorRightAlternative={errorRightAlternative}
        />

        <div className={classes.field}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>{TextPrimary('Peso da Questão')}</Grid>
            <Grid item>
              <input
                className={classes.numberField}
                type="number"
                min={1}
                max={10}
                step={0.25}
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.field}>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                style={{
                  textTransform: 'none',
                  border: `1px solid ${theme.palette.primary.main}`,
                  width: 140,
                  height: 45
                }}
                onClick={handleCancel}
              >
                <Text color="primary" variant={'button'}>
                  {' '}
                  {editingQuestion
                    ? 'Cancelar Edição'
                    : 'Cancelar Questão'}{' '}
                </Text>
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  textTransform: 'none',
                  backgroundColor: `${theme.palette.primary.main}`,
                  width: 140,
                  height: 45
                }}
                onClick={handleAddQuestion}
              >
                <Text color="white" variant={'button'}>
                  {' '}
                  {editingQuestion
                    ? 'Alterar Questão'
                    : 'Adicionar Questão'}{' '}
                </Text>
              </Button>
            </Grid>
            {!editingQuestion && (
              <Grid item>
                <Button
                  style={{
                    textTransform: 'none',
                    backgroundColor: `${theme.palette.primary.main}`,
                    width: 140,
                    height: 45
                  }}
                  onClick={() => setShowGenerationQuestioModal(true)}
                >
                  <Text color="white" variant={'button'}>
                    {' '}
                    Gerar com IA{' '}
                  </Text>
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Collapse>
    </div>
  );
}
