import React, { useState, useRef } from 'react';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Show from '~/components/atoms/Show';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import api from '~/services/api';

import { Grid } from '@material-ui/core';
import { AddIcon } from '~/components/atoms/Icons';

import GenerationQuestionaryForms from './GenerationQuestionaryForms';
import { useStyles } from './styles';
import { Backdrop, Fade, Modal, Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

function IAGenerationQuestionaryModal({
  open,
  handleClose,
  addQuestionFunction,
  questionsState
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [loadingCreation, setloadingCreation] = useState(false);
  const [creationMessage, setCreationMessage] = useState(null);
  const [error, setError] = useState('');
  const questionArrayDivRef = useRef(null);

  const [questionGenerationArray, setQuestionGenerationArray] = useState([
    {
      id: 0,
      difficulty: 1,
      subject: '',
      type: 'Escala Likert',
      numberOfQuestions: ''
    }
  ]);

  const secondaryText = (text) => (
    <Text color="gray" variant="lg" style={{ fontWeight: 400 }}>
      {text}
    </Text>
  );

  const startGeneration = async () => {
    for (let questionBlock of questionGenerationArray) {
      if (
        questionBlock.subject == '' ||
        questionBlock.numberOfQuestions == ''
      ) {
        setError(
          'Preencha as informações da questão para realizar a geração...'
        );
        setTimeout(() => {
          setError('');
        }, 3000);
        return;
      }
    }
    setloadingCreation(true);
    await api
      .get(`api/courses/generate_question_with_ia`, {
        params: { questionsArray: JSON.stringify(questionGenerationArray) }
      })
      .then((response) => {
        if (response.data.answer[0] == null) {
          setCreationMessage(false);
          setTimeout(() => {
            setloadingCreation(false);
            setCreationMessage(null);
          }, 3000);
        } else {
          setCreationMessage(true);
          setTimeout(() => {
            setloadingCreation(false);
            setCreationMessage(null);
            clearAndClose();
          }, 3000);
        }
        let startIndex = questionsState.length;
        response.data.answer.forEach((questions) => {
          var lines = questions.split('\n');
          lines.forEach((line) => {
            addQuestionFunction({
              options_attributes: [
                {
                  id: null,
                  correct: false,
                  text: `Discordo totalmente`,
                  letter: `A`
                },
                {
                  id: null,
                  correct: false,
                  text: `Discordo parcialmente`,
                  letter: `B`
                },
                {
                  id: null,
                  correct: false,
                  text: `Não concordo, nem discordo`,
                  letter: `C`
                },
                {
                  id: null,
                  correct: false,
                  text: `Concordo parcialmente`,
                  letter: `D`
                },
                {
                  id: null,
                  correct: false,
                  text: `Concordo totalmente`,
                  letter: `E`
                }
              ],
              statement: line,
              position: startIndex
            });
            startIndex++;
          });
        });
      })
      .catch((error) => console.log(error));
  };

  const handleNewExerciseForms = () => {
    setQuestionGenerationArray([
      ...questionGenerationArray,
      {
        id: questionGenerationArray.length,
        difficulty: 1,
        subject: '',
        type: 'Escala Likert',
        numberOfQuestions: ''
      }
    ]);

    setTimeout(() => {
      if (questionGenerationArray.length <= 1) {
        setTimeout(() => {
          if (questionArrayDivRef.current) {
            questionArrayDivRef.current.scrollTo({
              top: questionArrayDivRef.current.scrollHeight,
              behavior: 'smooth'
            });
          }
        }, 200);
      } else {
        setTimeout(() => {
          if (questionArrayDivRef.current) {
            questionArrayDivRef.current.scrollTo({
              top: questionArrayDivRef.current.scrollHeight,
              behavior: 'smooth'
            });
          }
        }, 100);
      }
    }, 20);
  };

  const handleChangeQuestionArray = (key, value, index) => {
    setQuestionGenerationArray((old) => {
      let auxArray = old;
      auxArray[index][key] = value;
      return auxArray;
    });
  };

  const clearAndClose = () => {
    handleClose();
    setQuestionGenerationArray([
      {
        id: 0,
        difficulty: 1,
        subject: '',
        type: 'Escala Likert',
        numberOfQuestions: ''
      }
    ]);
  };

  const deleteCard = (item) => {
    setQuestionGenerationArray((old) => {
      return old.filter((oldItem) => oldItem !== item);
    });
  };

  function Buttons() {
    return (
      <div className={classes.buttonsContainer}>
        <ButtonSquare onClick={clearAndClose} className={classes.button}>
          <Typography className={classes.cancelButton}>
            {i18n.t('Cancel')}
          </Typography>
        </ButtonSquare>
        <ButtonSquare
          backgroundColor={theme.palette.primary.main}
          className={classes.button}
          onClick={startGeneration}
        >
          <Typography className={classes.importButton}>
            {i18n.t('Confirm')}
          </Typography>
        </ButtonSquare>
      </div>
    );
  }

  function Title() {
    return (
      <>
        <div className={classes.titleContainer}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <ImportExportIcon style={{ fontSize: '24px' }} />
            <Text
              color="primary"
              variant="lgTitle"
              style={{ letterSpacing: 1, fontWeight: 500 }}
            >
              Geração com IA
            </Text>
          </div>
          <ButtonSquare onClick={clearAndClose}>
            <CloseIcon style={{ fontSize: '32px' }} />
          </ButtonSquare>
        </div>
        {secondaryText(
          'Preencha as informações abaixo e confirme para que a geração das questões likert seja iniciada.'
        )}
      </>
    );
  }

  return (
    <Modal
      id="modal-modal"
      disableEnforceFocus
      aria-labelledby="lecture-form"
      aria-describedby="modal-for-lecture-form"
      className={classes.modal}
      open={open}
      // onClose={}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        {loadingCreation ? (
          <Paper className={classes.paper}>
            {creationMessage === null ? (
              <div
                className={classes.loadingCreation_Container}
                style={{ gap: '12px' }}
              >
                <div className={classes.defaultLoader}></div>
                <Text
                  color="primary"
                  variant="lgTitle"
                  style={{ letterSpacing: 1, fontWeight: 500 }}
                >
                  Gerando questões...
                </Text>
              </div>
            ) : creationMessage === true ? (
              <div
                className={classes.loadingCreation_Container}
                style={{ gap: '4px' }}
              >
                <CheckCircleIcon className={classes.loadingCreation_Icon} />
                <div style={{ textAlign: 'text-center' }}>
                  <Text
                    color="primary"
                    variant="lgTitle"
                    style={{ letterSpacing: 1, fontWeight: 500 }}
                  >
                    Questões geradas com sucesso!
                  </Text>
                </div>
              </div>
            ) : (
              <div
                className={classes.loadingCreation_Container}
                style={{ gap: '4px' }}
              >
                <ErrorIcon className={classes.loadingCreation_Icon} />
                <div style={{ textAlign: 'center' }}>
                  <Text
                    color="primary"
                    variant="lgTitle"
                    style={{ letterSpacing: 1, fontWeight: 500 }}
                  >
                    Ocorreu um erro na geração de questão...
                  </Text>
                  <Text color="gray" variant="lg" style={{ letterSpacing: 1 }}>
                    Tente novamente, por favor!
                  </Text>
                </div>
              </div>
            )}
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            <Title />
            <div
              className={classes.generationExerciseFormsDiv}
              style={{ width: '100%' }}
              ref={questionArrayDivRef}
            >
              {questionGenerationArray.map((item, index) => {
                return (
                  <GenerationQuestionaryForms
                    key={item.id}
                    id={index}
                    subject={item.subject}
                    numberOfQuestions={item.numberOfQuestions}
                    deleteCardFunction={
                      questionGenerationArray.length == 1
                        ? null
                        : () => deleteCard(item)
                    }
                    onChange={(key, value) =>
                      handleChangeQuestionArray(key, value, index)
                    }
                  />
                );
              })}
            </div>
            <div className={classes.bottomButtonsContainer}>
              {error !== '' && (
                <Text
                  color="error"
                  variant="mediumText"
                  style={{ letterSpacing: 1 }}
                >
                  {error}
                </Text>
              )}
              <Grid
                className={`${classes.toggle} ${classes.field}`}
                container
                alignItems="center"
                spacing={1}
                onClick={handleNewExerciseForms}
              >
                <Grid item>
                  <div className={classes.iconCustomWraper}>
                    <AddIcon className={classes.iconCustom} />
                  </div>
                </Grid>
                <Grid item>
                  <Text color={'primary'} variant={'text'}>
                    {' '}
                    Adicionar modelo de questão{' '}
                  </Text>
                </Grid>
              </Grid>
              <Buttons />
            </div>
          </Paper>
        )}
      </Fade>
    </Modal>
  );
}

export default IAGenerationQuestionaryModal;
