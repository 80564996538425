import React from 'react';
import PropTypes from 'prop-types';

import Text from '~/components/atoms/Text';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import { DeleteIcon } from '~/components/atoms/Icons';
import ButtonSquare from '~/components/atoms/ButtonSquare';

function GenerationQuestionaryForms({
  id,
  subject,
  numberOfQuestions,
  deleteCardFunction,
  onChange = () => {}
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.exerciseFormsDiv}>
      <ButtonSquare
        className={
          deleteCardFunction
            ? classes.deleteButtonSquare
            : classes.deleteButtonSquareDisabled
        }
        onClick={deleteCardFunction}
      >
        <DeleteIcon
          className={
            deleteCardFunction
              ? classes.deleteButton
              : classes.deleteButtonDisabled
          }
        />
      </ButtonSquare>
      <div className={classes.formSectionDiv}>
        <Text
          color="primary"
          variant="lg"
          style={{ letterSpacing: 1, fontWeight: 500 }}
        >
          Assunto:
        </Text>
        <input
          type="text"
          id={`questionSubject${id}`}
          defaultValue={subject}
          className={classes.subjectInput}
          onChange={(e) => onChange('subject', e.target.value)}
        />
      </div>
      <div
        className={classes.formSectionDiv}
        style={{ flexDirection: 'row', marginTop: '10px' }}
      >
        <Text
          color="primary"
          variant="lg"
          style={{ letterSpacing: 1, fontWeight: 500 }}
        >
          Quantidade:
        </Text>
        <input
          type="number"
          id={`numberOfQuestions${id}`}
          defaultValue={numberOfQuestions}
          className={classes.numberOfQuestionsInput}
          onChange={(e) => onChange('numberOfQuestions', e.target.value)}
          style={{ width: '39px' }}
        />
      </div>
    </div>
  );
}

GenerationQuestionaryForms.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subject: PropTypes.string.isRequired,
  numberOfQuestions: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  deleteCardFunction: PropTypes.func,
  onChange: PropTypes.func
};

GenerationQuestionaryForms.defaultProps = {
  deleteCardFunction: null,
  onChange: () => {}
};

export default GenerationQuestionaryForms;
