import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    padding: '8px 16px',
    gap: '16px',
    borderRadius: '8px',
    width: '100%',
    border: `1px solid #888`,
    marginTop: '10px'
  },
  icon: {
    fontSize: 32,
    marginRight: '16px',
    color: theme.palette.primary.main
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px'
  },
  title: {
    fontWeight: '500',
    fontSize: '16px',
    fontHeight: '24px',
    color: theme.palette.primary.main
  },
  description: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
    color: 'gray',
    textTransform: 'none'
  }
}));
