import React, { useEffect, useState, useContext, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import ButtonSquare from '~/components/atoms/ButtonSquare';
import Show from '~/components/atoms/Show';
import Text from '~/components/atoms/Text';
import i18n from '~/I18n';
import api from '~/services/api';

import { Grid } from '@material-ui/core';
import { AddIcon } from '~/components/atoms/Icons';

import GenerationExerciseForms from './GenerationExerciseForms';
import { useStyles } from './styles';
import { Backdrop, Fade, Modal, Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ImportExportIcon from '@material-ui/icons/ImportExport';

function IAGenerationExerciseModal({
  open,
  handleClose,
  addQuestionFunction,
  questionsState
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [questionGenerationArray, setQuestionGenerationArray] = useState([
    { id: 0, difficulty: '', subject: '', type: '', numberOfQuestions: '' }
  ]);

  const [loadingCreation, setloadingCreation] = useState(false);
  const [creationMessage, setCreationMessage] = useState(null);
  const [error, setError] = useState('');
  const questionArrayDivRef = useRef(null);

  const secondaryText = (text) => (
    <Text color="gray" variant="lg" style={{ fontWeight: 400 }}>
      {text}
    </Text>
  );

  const startGeneration = async () => {
    for (let questionBlock of questionGenerationArray) {
      if (
        questionBlock.subject == '' ||
        questionBlock.difficulty == '' ||
        questionBlock.type == '' ||
        questionBlock.numberOfQuestions == ''
      ) {
        setError(
          'Preencha as informações da questão para realizar a geração...'
        );
        setTimeout(() => {
          setError('');
        }, 3000);
        return;
      }
    }
    setloadingCreation(true);
    await api
      .get(`api/courses/generate_question_with_ia`, {
        params: { questionsArray: JSON.stringify(questionGenerationArray) }
      })
      .then((response) => {
        if (response.data.answer[0] == null) {
          setCreationMessage(false);
          setTimeout(() => {
            setloadingCreation(false);
            setCreationMessage(null);
          }, 3000);
        } else {
          setCreationMessage(true);
          setTimeout(() => {
            setloadingCreation(false);
            setCreationMessage(null);
            clearAndClose();
          }, 3000);
        }
        let startIndex = questionsState.length;
        response.data.answer.forEach((questions) => {
          let newQuestion = {
            weight: 1.0,
            alternatives: [],
            statement: '',
            explanation: '',
            position: startIndex
          };

          var lines = questions.split('\n');
          lines.forEach((line, index) => {
            if (line.toLowerCase().includes('resposta correta')) {
              newQuestion.alternatives.forEach((alternative) => {
                if (
                  line.split(':')[1][1].toLowerCase() ==
                  alternative.letter.toLowerCase()
                ) {
                  alternative.correct = true;
                }
              });
              addQuestionFunction(newQuestion);
              startIndex++;
              newQuestion = {
                weight: 1.0,
                alternatives: [],
                statement: '',
                explanation: '',
                position: startIndex
              };
            } else if (
              index + 1 < lines.length &&
              lines[index + 1].toLowerCase().includes('a)')
            ) {
              let statementLines = [];
              for (let i = index; i >= 0; i--) {
                let previousLine = lines[i].trim();
                if (previousLine.includes('---')) {
                  break;
                }
                statementLines.unshift(`<p>${previousLine}</p>`);
              }
              newQuestion.statement = statementLines.join(' ');
            } else if (
              line.toLowerCase().includes('a)') ||
              line.toLowerCase().includes('b)') ||
              line.toLowerCase().includes('c)') ||
              line.toLowerCase().includes('d)') ||
              line.toLowerCase().includes('e)')
            ) {
              newQuestion.alternatives.push({
                id: null,
                correct: false,
                text: `<p>${line.substring(2)}</p>`,
                letter: `${String.fromCharCode(
                  65 + newQuestion.alternatives.length
                )}`
              });
            }
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewExerciseForms = () => {
    setQuestionGenerationArray([
      ...questionGenerationArray,
      {
        id: questionGenerationArray.length,
        difficulty: '',
        subject: '',
        type: '',
        numberOfQuestions: ''
      }
    ]);

    setTimeout(() => {
      if (questionGenerationArray.length <= 1) {
        setTimeout(() => {
          if (questionArrayDivRef.current) {
            questionArrayDivRef.current.scrollTo({
              top: questionArrayDivRef.current.scrollHeight,
              behavior: 'smooth'
            });
          }
        }, 200);
      } else {
        setTimeout(() => {
          if (questionArrayDivRef.current) {
            questionArrayDivRef.current.scrollTo({
              top: questionArrayDivRef.current.scrollHeight,
              behavior: 'smooth'
            });
          }
        }, 100);
      }
    }, 20);
  };

  const handleChangeQuestionArray = (key, value, index) => {
    setQuestionGenerationArray((old) => {
      let auxArray = old;
      auxArray[index][key] = value;
      return auxArray;
    });
  };

  const clearAndClose = () => {
    handleClose();
    setQuestionGenerationArray([
      {
        id: 0,
        difficulty: '',
        subject: '',
        type: '',
        numberOfQuestions: ''
      }
    ]);
  };

  const deleteCard = (item) => {
    setQuestionGenerationArray((old) => {
      return old.filter((oldItem) => oldItem !== item);
    });
  };

  function Buttons() {
    return (
      <div className={classes.buttonsContainer}>
        <ButtonSquare onClick={clearAndClose} className={classes.button}>
          <Typography className={classes.cancelButton}>
            {i18n.t('Cancel')}
          </Typography>
        </ButtonSquare>
        <ButtonSquare
          backgroundColor={theme.palette.primary.main}
          className={classes.button}
          onClick={startGeneration}
        >
          <Typography className={classes.importButton}>
            {i18n.t('Confirm')}
          </Typography>
        </ButtonSquare>
      </div>
    );
  }

  function Title() {
    return (
      <>
        <div className={classes.titleContainer}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <ImportExportIcon style={{ fontSize: '24px' }} />
            <Text
              color="primary"
              variant="lgTitle"
              style={{ letterSpacing: 1, fontWeight: 500 }}
            >
              Geração com IA
            </Text>
          </div>
          <ButtonSquare onClick={clearAndClose}>
            <CloseIcon style={{ fontSize: '32px' }} />
          </ButtonSquare>
        </div>
        {secondaryText(
          'Preencha as informações abaixo e confirme para que a geração das questões seja iniciada.'
        )}
      </>
    );
  }

  return (
    <Modal
      id="modal-modal"
      disableEnforceFocus
      aria-labelledby="lecture-form"
      aria-describedby="modal-for-lecture-form"
      className={classes.modal}
      open={open}
      // onClose={}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        {loadingCreation ? (
          <Paper className={classes.paper}>
            {creationMessage === null ? (
              <div
                className={classes.loadingCreation_Container}
                style={{ gap: '12px' }}
              >
                <div className={classes.defaultLoader}></div>
                <Text
                  color="primary"
                  variant="lgTitle"
                  style={{ letterSpacing: 1, fontWeight: 500 }}
                >
                  Gerando questões...
                </Text>
              </div>
            ) : creationMessage === true ? (
              <div
                className={classes.loadingCreation_Container}
                style={{ gap: '4px' }}
              >
                <CheckCircleIcon className={classes.loadingCreation_Icon} />
                <div style={{ textAlign: 'text-center' }}>
                  <Text
                    color="primary"
                    variant="lgTitle"
                    style={{ letterSpacing: 1, fontWeight: 500 }}
                  >
                    Questões geradas com sucesso!
                  </Text>
                </div>
              </div>
            ) : (
              <div
                className={classes.loadingCreation_Container}
                style={{ gap: '4px' }}
              >
                <ErrorIcon className={classes.loadingCreation_Icon} />
                <div style={{ textAlign: 'center' }}>
                  <Text
                    color="primary"
                    variant="lgTitle"
                    style={{ letterSpacing: 1, fontWeight: 500 }}
                  >
                    Ocorreu um erro na geração de questão...
                  </Text>
                  <Text color="gray" variant="lg" style={{ letterSpacing: 1 }}>
                    Tente novamente, por favor!
                  </Text>
                </div>
              </div>
            )}
          </Paper>
        ) : (
          <Paper className={classes.paper}>
            <Title />
            <div className={classes.generationExerciseFormsContainer}>
              <div
                className={classes.generationExerciseFormsDiv}
                style={{ width: '100%' }}
                ref={questionArrayDivRef}
              >
                {questionGenerationArray.map((item, index) => {
                  return (
                    <GenerationExerciseForms
                      key={item.id}
                      id={index}
                      difficulty={item.difficulty}
                      subject={item.subject}
                      type={item.type}
                      numberOfQuestions={item.numberOfQuestions}
                      deleteCardFunction={
                        questionGenerationArray.length == 1
                          ? null
                          : () => deleteCard(item)
                      }
                      onChange={(key, value) =>
                        handleChangeQuestionArray(key, value, index)
                      }
                    />
                  );
                })}
              </div>
            </div>
            <div className={classes.bottomButtonsContainer}>
              {error !== '' && (
                <Text
                  color="error"
                  variant="mediumText"
                  style={{ letterSpacing: 1 }}
                >
                  {error}
                </Text>
              )}
              <Grid
                className={`${classes.toggle} ${classes.field}`}
                container
                alignItems="center"
                spacing={1}
                onClick={handleNewExerciseForms}
              >
                <Grid item>
                  <div className={classes.iconCustomWraper}>
                    <AddIcon className={classes.iconCustom} />
                  </div>
                </Grid>
                <Grid item>
                  <Text color={'primary'} variant={'text'}>
                    {' '}
                    Adicionar modelo de questão{' '}
                  </Text>
                </Grid>
              </Grid>
              <Buttons />
            </div>
          </Paper>
        )}
      </Fade>
    </Modal>
  );
}

export default IAGenerationExerciseModal;
